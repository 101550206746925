@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sidebar-width: 340px; /* Default value */
  --brand-color: #ea002c;
}

body,
html,
#root {
  height: 100%;
  width: 100%;
  
  
}

body.default-bg{
  background: radial-gradient(
    51.69% 50% at 50% 50%,
    #ffffff 0%,
    #ffffff 0.01%,
    #dfdbdb 34.9%,
    #cdcbcb 53.86%,
    #bdbaba 73.96%,
    #b5b1b1 100%
    );
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Shared styles */

.shadow {
  box-shadow: 0px 4px 20px 0px rgba(95, 103, 135, 0.05);
}

* {
  user-select: none;
}

.freeze {
  height: 100%;
  width: 100vw;
  overflow: hidden;
}

.fade-out {
  animation: fadeOut 0.6s forwards; /* 1s for duration and forwards to keep the final state */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.6s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preview-bg {
  background: radial-gradient(
    51.69% 50% at 50% 50%,
    #ffffff 0%,
    #ffffff 0.01%,
    #dfdbdb 34.9%,
    #cdcbcb 53.86%,
    #bdbaba 73.96%,
    #b5b1b1 100%
  );
}
